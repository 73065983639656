<template>
<div class="branchesList" >
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <div class="col-sm-3">
        <label for="">Фильтрация по странам</label>
        <a-select
            mode="multiple"
            placeholder="Выберите страну"
            v-model="dataFilter.countries"
            style="width: 230px"
            optionLabelProp="label"
            optionFilterProp="label"
            @change="applyFilter"
            @search="searchCountry"
          >
          <a-select-option v-for="country in countries" :key="country.id" :value="country.id" :label="country.title">
            {{country.title}}
          </a-select-option>
        </a-select>
      </div>

      <div class="col-sm-3">
        <label for="">Фильтрация по областям</label>
        <a-select
            mode="multiple"
            placeholder="Выберите область"
            v-model="dataFilter.regions"
            style="width: 230px"
            optionLabelProp="label"
            optionFilterProp="label"
            @change="applyFilter"
            @search="searchRegion"
          >
          <a-select-option v-for="region in regions" :key="region.id" :value="region.id" :label="region.title">
            {{region.title}}
          </a-select-option>
        </a-select>
      </div>

      <div class="col-sm-3">
        <label for="">Фильтрация по городам</label>
        <a-select
            mode="multiple"
            placeholder="Выберите город"
            v-model="dataFilter.cities"
            style="width: 230px"
            optionLabelProp="label"
            optionFilterProp="label"
            @change="applyFilter"
            @search="searchCity"
          >
          <a-select-option v-for="city in cities" :key="city.id" :value="city.id" :label="city.title">
            {{city.title}}
          </a-select-option>
        </a-select>
      </div>
      <div class="col-sm-3">
        <label for="">Поиск по названию</label>
        <template>
          <a-input placeholder="Название офиса"
          v-model="itemName"
          @change="applyFilterAutocomplete"
          />
        </template>
      </div>

    </div>
  </div>
  <mini-loader v-if="load" />
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5 class="text-dark">Офисы</h5>
      <a @click="createItem">
        <span class=" p-1">
          <button type="button" class="btn btn-success">Добавить
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 20 20">
              <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
            </svg>
          </button>

        </span>
      </a>
    </div>
    <table class="table table-striped" id="branchesList">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Наименование</th>
          <th scope="col">Страна</th>
          <th scope="col">Регион</th>
          <th scope="col">Город</th>
          <th scope="col">Действие</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, index) in data">
          <th scope="row">{{index+1}}</th>
          <td>{{value.title}}</td>
          <td v-if="value.country">{{value.country.title}}</td> <td v-else>--</td>
          <td v-if="value.region">{{value.region.title}}</td> <td v-else>--</td>
          <td v-if="value.city">{{value.city.title}}</td> <td v-else>--</td>
          <td>
            <a  @click="showItem(value)">
              <span class="border rounded border-primary m-1 p-2 text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                </svg>
              </span>
            </a>
            <a @click="editItem(value)">
              <span class="border rounded border-success m-1 p-2 text-success">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                </svg>
              </span>
            </a>
            <a @click="deleteItem(index, value)">
              <span class="border rounded border-danger m-1 p-2 text-danger">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="m-10 text-dark text-center" style="border: 1px solid #DFE1E4; padding: 10px">1 - {{data.length}} данных  из {{totalResults}}</p>
  </div>

<!-- Show Modal -->
<a-modal v-model="visibleShowModal" title="Детали"  footer="">
  <table class="table table-striped" >
    <tbody>
      <tr>
        <td>ID</td>
        <td>{{item.id}}</td>
      </tr>
      <tr>
        <td>Наименование</td>
        <td>{{item.title}}</td>
      </tr>
      <tr>
        <td>Адрес</td>
        <td>{{item.address}}</td>
      </tr>
      <tr>
        <td>Телефон</td>
        <td>{{item.phone}}</td>
      </tr>
      <tr>
        <td>Страна</td>
        <td v-if="item.country">{{item.country.title}}</td> <td v-else>--</td>
      </tr>
      <tr>
        <td>Регион</td>
        <td v-if="item.region">{{item.region.title}}</td> <td v-else>0</td>
      </tr>
      <tr>
        <td>Город</td>
        <td v-if="item.city">{{item.city.title}}</td> <td v-else>0</td>
      </tr>
      <tr>
        <td>Количество сотрудников</td>
        <td v-if="item.users">{{item.users.length}}</td> <td v-else>0</td>
      </tr>
    </tbody>
  </table>
</a-modal>

<!-- Edit modal -->
<a-modal v-model="visibleEditModal" title="Изменить данные"  footer="">
  <div class="form-group">
    <label for="exampleInputEmail1">Наименование</label>
    <input type="email" class="form-control" v-model="item.title" :class="{'is-invalid': validateErrors.title}" placeholder="Наименование">
    <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail1">Адрес</label>
    <input type="email" class="form-control" v-model="item.address" :class="{'is-invalid': validateErrors.address}" placeholder="Адрес">
    <div class="invalid-feedback" v-for="value in validateErrors.address"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail1">Телефон</label>
    <input type="email" class="form-control" v-model="item.phone" :class="{'is-invalid': validateErrors.phone}" placeholder="Телефон">
    <div class="invalid-feedback" v-for="value in validateErrors.phone"> {{value}} </div>
  </div>

  <div  v-if="editCountryRegionCity">
    <div class="form-group">
      <label for="exampleInputEmail">Страна</label>
      <a-select
        show-search
        v-model="item.country_id"
        placeholder="Выберите страну"
        :class="{'is-invalid': validateErrors.country_id}"
        :default-active-first-option="false"
        style="width: 100%"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="searchSingleCountry"
        @change="changeCountryId"
      >
        <a-select-option v-for="d in dataSourceCountries" :key="d.id">
          {{ d.title }}
        </a-select-option>
      </a-select>
      <div class="invalid-feedback" v-for="value in validateErrors.country_id"> {{value}} </div>
    </div>
    <div class="form-group">
      <label for="exampleInputEmail">Регион</label>
      <a-select
        show-search
        v-model="item.region_id"
        placeholder="Выберите региона"
        :class="{'is-invalid': validateErrors.region_id}"
        :default-active-first-option="false"
        style="width: 100%"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="searchSingleRegion"
        @change="changeRegionId"
      >
        <a-select-option v-for="d in dataSourceRegions" :key="d.id">
          {{ d.title }}
        </a-select-option>
      </a-select>
      <div class="invalid-feedback" v-for="value in validateErrors.region_id"> {{value}} </div>
    </div>
    <div class="form-group">
      <label for="exampleInputEmail">Город</label>
      <a-select
        show-search
        v-model="item.district_id"
        placeholder="Выберите города"
        :class="{'is-invalid': validateErrors.district_id}"
        :default-active-first-option="false"
        style="width: 100%"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="searchSingleCity"
      >
        <a-select-option v-for="d in dataSourceCities" :key="d.id">
          {{ d.title }}
        </a-select-option>
      </a-select>
      <div class="invalid-feedback" v-for="value in validateErrors.district_id"> {{value}} </div>
    </div>
  </div>

  <div class="form-group" v-else>
    <div>
      <label for="exampleInputEmail1">Страна</label>
      <input type="hidden" v-model="item.country_id">
      <div class="d-flex">
        <input type="email" class="form-control" v-if="item.country" v-model="item.country.title" disabled :class="{'is-invalid': validateErrors.country_id}">
      </div>
      <div class="invalid-feedback" v-for="value in validateErrors.country_id"> {{value}} </div>
    </div>
    <div>
      <label for="exampleInputEmail1">Регион</label>
      <input type="hidden" v-model="item.region_id">
      <div class="d-flex">
        <input type="email" class="form-control" v-if="item.region" v-model="item.region.title" disabled :class="{'is-invalid': validateErrors.region_id}">
      </div>
      <div class="invalid-feedback" v-for="value in validateErrors.region_id"> {{value}} </div>
    </div>
    <div>
      <label for="exampleInputEmail1">Город</label>
      <input type="hidden" v-model="item.district_id">
      <div class="d-flex">
          <input type="email" class="form-control" v-if="item.city" v-model="item.city.title" disabled :class="{'is-invalid': validateErrors.district_id}">
        <button class="btn btn-info" @click="editCountryRegionCity=true; item.country_id=''; item.region_id=''; item.district_id=''">Изменить</button>
      </div>
      <div class="invalid-feedback" v-for="value in validateErrors.district_id"> {{value}} </div>
    </div>
  </div>
  <div class="d-flex">
    <button type="submit" class="ml-auto btn btn-primary" @click="updateItem">Изменить</button>
  </div>
</a-modal>

<!-- Create Modal -->
<a-modal v-model="visibleCreateModal" title="Добавить новую"  footer="">
  <div class="form-group">
    <label for="exampleInputEmail">Наименование</label>
    <input type="text" class="form-control" v-model="newItem.title" :class="{'is-invalid': validateErrors.title}" placeholder="Наименование">
    <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
  </div>

  <div class="form-group">
    <label for="exampleInputEmail">Адрес</label>
    <input type="text" class="form-control" v-model="newItem.address" :class="{'is-invalid': validateErrors.address}" placeholder="Адрес">
    <div class="invalid-feedback" v-for="value in validateErrors.address"> {{value}} </div>
  </div>

  <div class="form-group">
    <label for="exampleInputEmail">Телефон</label>
    <input type="text" class="form-control" v-model="newItem.phone" :class="{'is-invalid': validateErrors.phone}" placeholder="Телефон">
    <div class="invalid-feedback" v-for="value in validateErrors.phone"> {{value}} </div>
  </div>

  <div class="form-group">
    <label for="exampleInputEmail">Страна</label>
    <a-select
      show-search
      v-model="newItem.country_id"
      placeholder="Выберите страну"
      :class="{'is-invalid': validateErrors.country_id}"
      :default-active-first-option="false"
      style="width: 100%"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      @search="searchSingleCountry"
      @change="changeCountryId"
    >
      <a-select-option v-for="d in dataSourceCountries" :key="d.id">
        {{ d.title }}
      </a-select-option>
    </a-select>
    <div class="invalid-feedback" v-for="value in validateErrors.country_id"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Регион</label>
    <a-select
      show-search
      v-model="newItem.region_id"
      placeholder="Выберите региона"
      :class="{'is-invalid': validateErrors.region_id}"
      :default-active-first-option="false"
      style="width: 100%"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      @search="searchSingleRegion"
      @change="changeRegionId"
    >
      <a-select-option v-for="d in dataSourceRegions" :key="d.id">
        {{ d.title }}
      </a-select-option>
    </a-select>
    <div class="invalid-feedback" v-for="value in validateErrors.region_id"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Город</label>
    <a-select
      show-search
      v-model="newItem.district_id"
      placeholder="Выберите города"
      :class="{'is-invalid': validateErrors.district_id}"
      :default-active-first-option="false"
      style="width: 100%"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      @search="searchSingleCity"
    >
      <a-select-option v-for="d in dataSourceCities" :key="d.id">
        {{ d.title }}
      </a-select-option>
    </a-select>
    <div class="invalid-feedback" v-for="value in validateErrors.district_id"> {{value}} </div>
  </div>
  <div class="d-flex">
    <button type="submit" class="ml-auto btn btn-primary" @click="storeItem">Добавить</button>
  </div>
</a-modal>

<!-- Delete modal -->
<a-modal v-model="visibleDeleteModal" title="Подтверждение на удаление" ok-text="Удалить" cancel-text="Отменить" @ok="destroyItem">
  <h6 style="color: red">Точно хотите безвозвратно удалить ?</h6>
</a-modal>
</div>


</template>

<script>
import axios from 'axios'

const data = '';
export default {
  name : 'Branches',
  data(){
    return {
      data,
      item: {
        type: 'international',
        title: '',
        address: '',
        phone: '',
        country_id: '',
        region_id: '',
        district_id: ''
      },
      newItem: {
        type: 'international',
        title: '',
        address: '',
        phone: '',
        country_id: '',
        region_id: '',
        district_id: '',
      },
      validateErrors: [],

      dataSourceCountries: [],
      dataSourceRegions: [],
      dataSourceCities: [],
      dataFilterSingle: {
        countries: [],
        regions: []
      },

      countries: [
        {
          id: 192,
          title: 'Узбекистан'
        }
      ],
      regions: [
        {
          id: 1327,
          title: 'Ташкентская обл.'
        }
      ],
      cities: [
        {
          id: 15733,
          title: 'Ташкент'
        }
      ],

      dataFilter: {
        countries: [],
        regions: [],
        cities: []
      },
      itemName: '',

      load: false,
      visibleShowModal: false,
      visibleEditModal: false,
      visibleCreateModal: false,
      visibleDeleteModal: false,
      editCountryRegionCity: false,

      totalResults: 0,
      old_height: 0,
      last_page: 1,
      index: ''


    }
  },
  mounted(){
    this.load = true;
    axios.get('/branches/international').then(response => {
      if(response.status == 200){
        this.data = response.data.data;
        this.load = false;
        this.totalResults = response.data.total
        this.last_page = response.data.last_page
      }else if(response.status == 204){
        this.load = false;
        this.$message.error(
              'Филиалов не найдено',
              3,
        );
      }
    }).catch(error => {
      this.load = false;
      this.$message.error(
            'Филиалов не найдено',
            3,
      );
    });

    window.onscroll = () => {
        let height = document.getElementById('branchesList').clientHeight
        if ((window.innerHeight + document.documentElement.scrollTop) >= height) {
          if(this.old_height!=height){
            this.old_height = height;
            let limit = this.data.length/this.$store.state.paginateCount + 1;
            if(limit <= this.last_page){
              if(this.dataFilter.countries || this.dataFilter.regions || this.dataFilter.cities || this.itemName){
                this.load = true;
                axios.post('/branches/international/autocomplete/search/with/paginate', this.dataFilter,  {params: {page: limit, query: this.itemName}}).then(response => {
                  if(response.status == 200){
                      this.totalResults = response.data.total;
                      this.data = this.data.concat(response.data.data);
                      this.load = false
                  }else if(response.status == 204){
                    this.load = false;
                    this.$message.error(
                      'Городов не найдено',
                      3
                    );
                  }
                }).catch(error => {
                  if(error.response.status == 500){
                    this.load = false;
                    this.$message.error(
                      'Ошибка сервера',
                      3
                    );
                  }else if(error.response.status == 204){
                    this.load = false;
                    this.$message.error(
                      'Городов не найдено',
                      3
                    );
                  }else{
                    this.load = false
                  }
                });
              }else{
                this.load = true;
                axios.get('/branches/international', {params: {page: limit}}).then(response => {
                  if(response.status == 200){
                      this.totalResults = response.data.total;
                      this.data = this.data.concat(response.data.data);
                      this.load = false
                  }else if(response.status == 204){
                    this.load = false;
                    this.$message.error(
                      'Городов не найдено',
                      3
                    );
                  }
                }).catch(error => {
                  if(error.response.status == 500){
                    this.load = false;
                    this.$message.error(
                      'Ошибка сервера',
                      3
                    );
                  }else if(error.response.status == 204){
                    this.load = false;
                    this.$message.error(
                      'Городов не найдено',
                      3
                    );
                  }else{
                    this.load = false
                  }
                });
              }
            }
          }
        }
    };

  },
  methods: {
    showItem(value){
      this.item = value;
      this.visibleShowModal = true;
    },
    editItem(value){
      this.item = value;
      this.visibleEditModal = true;
    },
    createItem(){
      this.visibleCreateModal = true
    },
    deleteItem(index, value){
      this.index = index;
      this.item = value;
      this.visibleDeleteModal = true;
    },

    updateItem(){
      this.load = true;
      axios.put('/branches/'+this.item.id, this.item)
      .then(response => {
        if(response.status == 200){
          this.item.country.title = response.data.country.title
          this.item.region.title = response.data.region.title
          this.item.city.title = response.data.city.title
          this.load = false;
          this.visibleEditModal = false
          this.$message.success(
                'Успешно изменено',
                3,
          );
        }else if(response.status == 404){
          this.$message.error(
                response.data.error,
                3,
          );
          this.load = false;
        }
      }).catch(error => {
        if(error.response.status == 422){
          this.load = false;
          this.$message.error(
                error.response.data.message,
                3,
          );
          this.validateErrors = error.response.data.errors
        }else if(error.response.status == 404){
          this.load = false;
          this.$message.error(
                error.response.data.error,
                3,
          );
          this.load = false;
        }else if(error.response.status == 500){
          this.$message.error(
                'Ошибка сервера',
                3,
          );
          this.load = false;
        }
      });
    },
    storeItem(){
      this.load = true;
      axios.post('/branches', this.newItem)
      .then(response => {
        if(response.status == 201){
          this.visibleCreateModal = false;
          this.load = false;
          this.newItem.title = '';
          this.$message.success(
                'Успешно добавлено',
                3,
          );
        }else{
          this.load = false;
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.load = false;
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else if(error.response.status == 422){
          this.validateErrors = error.response.data.errors
          this.load = false
          this.$message.error(
            error.response.data.message,
            3
          );
        }
      });
    },
    destroyItem(){
      axios.delete('/branches/'+this.item.id).then(response => {
         if(response.status==200){
          this.$message.success(
                response.data.success,
                3,
          );
          this.data.splice(this.index, 1)
          this.totalResults--;
        }
      }).catch(error => {
        if(error.response.status==400){
          this.$message.error(
                error.response.data.error,
                3,
          );
        }else if(error.response.status == 404){
          this.$message.error(
                error.response.data.error,
                3,
          );
        }else if(error.response.status == 500){
          this.$message.error(
                'Ошибка сервера',
                3,
          );
        }
      }).finally(() => {
          this.visibleDeleteModal = false
      });
    },

    changeCountryId(value){
      this.dataFilterSingle.countries[0] = value;
    },
    changeRegionId(value){
      this.dataFilterSingle.regions[0] = value;
    },

    searchSingleCountry(value){
      console.log(value)
      if(value.length > 1){
        axios.get('/international/countries/autocomplete/search', {params: {query: value}})
        .then(response => {
          if(response.status == 200){
            this.dataSourceCountries = response.data;
          }else if(response.status == 204){
            this.$message.error(
              'Нет соответствующих стран',
              3
            )
          }
        }).catch(error => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
          }else{
            this.$message.error(
              'Нет соответствующих стран',
              3
            )
          }
        });
      }
    },
    searchSingleRegion(value){
      axios.post('/international/regions/autocomplete/search', this.dataFilterSingle, {params: {query: value}})
      .then(response => {
        if(response.status == 200){
          this.dataSourceRegions = response.data;
        }else if(response.status == 204){
          this.$message.error(
            'Нет соответствующего региона',
            3
          )
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else{
          this.$message.error(
            'Нет соответствующего региона',
            3
          )
        }
      });
    },
    searchSingleCity(value){
      axios.post('/international/cities/autocomplete/search', this.dataFilterSingle, {params: {query: value}})
      .then(response => {
        if(response.status == 200){
          this.dataSourceCities = response.data;
        }else if(response.status == 204){
          this.$message.error(
            'Нет соответствующего города',
            3
          )
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else{
          this.$message.error(
            'Нет соответствующего города',
            3
          )
        }
      });
    },

    applyFilter(){
      this.old_height = 0;
      this.load = true;
      axios.post('/branches/international/autocomplete/search/with/paginate', this.dataFilter, {params: {query: this.itemName}})
      .then(response => {
        if(response.status == 200){
          this.data = response.data.data;
          this.totalResults = response.data.total;
          this.load = false;
          this.last_page = response.data.last_page;
        }else if(response.status == 204){
          this.data = [];
          this.totalResults = 0;
          this.load = false;
          this.$message.error(
            'Офисов не найдено',
            3
          );
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
          this.load = false;
        }else if(error.response.status == 204){
          this.data = [];
          this.totalResults = 0;
          this.load = false;
          this.$message.error(
            'Офисов не найдено',
            3
          );
        }else{
          this.load = false
        }
      });
    },
    applyFilterAutocomplete(){
      if(this.itemName.length > 1 || this.itemName.length == 0){
        this.old_height = 0;
        this.load = true;
        axios.post('/branches/international/autocomplete/search/with/paginate', this.dataFilter, {params: {query: this.itemName}})
        .then(response => {
          if(response.status == 200){
            this.data = response.data.data;
            this.totalResults = response.data.total;
            this.load = false;
            this.last_page = response.data.last_page;
          }else if(response.status == 204){
            this.data = [];
            this.totalResults = 0;
            this.load = false;
            this.$message.error(
              'Офисов не найдено',
              3
            );
          }
        }).catch(error => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
            this.load = false;
          }else if(error.response.status == 204){
            this.data = [];
            this.totalResults = 0;
            this.load = false;
            this.$message.error(
              'Офисов не найдено',
              3
            );
          }else{
            this.load = false
          }
        });
      }
    },
    searchCountry(value){
      if(value.length>1){
        axios.get('/international/countries/autocomplete/search', {params: {query: value}}).then(response => {
          if(response.status == 200){
            this.countries = response.data;
          }else if(response.status == 204){
            this.$message.error(
                  'Нет соответствующих стран',
                  3,
            );
          }
        }).catch(error => {
          if(error.response.status == 204){
            this.$message.error(
                  'Нет соответствующих стран',
                  3,
            );
          }else if(error.response.status == 500){
            this.$message.error(
                  'Ошибка сервера',
                  3,
            );
          }
        })
      }
    },
    searchRegion(value){
      if(value.length>1){
        axios.post('/international/regions/autocomplete/search', this.dataFilter, {params: {query: value}}).then(response => {
          if(response.status == 200){
            this.regions = response.data
          }else if(response.status == 204){
            this.$message.error(
                  'Нет соответствующих регионов',
                  3,
            );
          }
        }).catch(error => {
          if(error.response.status == 204){
            this.$message.error(
                  'Нет соответствующих регионов',
                  3,
            );
          }else if(error.response.status == 500){
            this.$message.error(
                  'Ошибка сервера',
                  3,
            );
          }
        })
      }
    },
    searchCity(value){
      if(value.length>1){
        axios.post('/international/cities/autocomplete/search', this.dataFilter, {params: {query: value}}).then(response => {
          if(response.status == 200){
            this.cities = response.data
          }else if(response.status == 204){
            this.$message.error(
                  'Нет соответствующих городов',
                  3,
            );
          }
        }).catch(error => {
          if(error.response.status == 204){
            this.$message.error(
                  'Нет соответствующих городов',
                  3,
            );
          }else if(error.response.status == 500){
            this.$message.error(
                  'Ошибка сервера',
                  3,
            );
          }
        });
      }
    }
  }
}
</script>
<style>
  .ant-modal-mask{
    z-index: 2000 !important;
  }
  .ant-modal-wrap{
    z-index: 2000 !important;
  }
  .ant-message{
    z-index: 2010 !important
  }
  .ant-message-notice{
    z-index: 2020 !important;
  }
  .ant-message-notice-content{
    z-index: 2020 !important;
  }
  .ant-select-dropdown{
    z-index: 2000 !important;
  }
</style>
